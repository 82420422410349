@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;900&display=swap");

:root {
  --mainColor: #452372;
  --mainSecondaryColor: #5C3998;
  --secondaryColor: #a582d2;
  --textColor: #aba8a8;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.3rem;
}

p {
  font-size: 1rem;
}
